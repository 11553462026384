div.loading-container {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0000007a;
  z-index: 9999;
  display: flex;
  opacity: 1;

  div.loader {
    z-index: 1;
    width: 100px;
    height: 100px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border-top: 8px solid transparent !important;
    left: 51%;
    position: absolute;
    top: 53%;
    -webkit-animation: spin 0.8s linear infinite;
    animation: spin 0.8s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}