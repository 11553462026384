button.btn-export {
	float: right;
    background-color: #ee7421;
    border: 1px solid #ee7421;
    color: white;

    &:hover {
      color: #ee7421;
      background-color: white;
    }
}