div.logo-loading-container {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 9999;
  display: flex;
  opacity: 1;

  div.logo-loader {
    width: 300px;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #000000;
    font-weight: bold;
    line-height: 3;
  }
}